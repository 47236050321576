<template>
  <b-card>
    <div class="d-flex justify-content-start align-items-center mt-1 ml-2">
      <span class="input-text">请输入运单ID</span>
      <b-form-input
        v-model="shipment_id"
        class="input"
        type="number"
        :class="shipmentIdError ? 'red-border' : '' "
      />
      <b-button
        variant="relief-primary ml-4 btn"
        @click="handleSeaShipment"
      >
        确认
      </b-button>
    </div>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center align-items-center loading-box"
    >
      <b-spinner
        variant="primary"
        class="mr-1"
        type="grow"
      />
      <div class="loading-text">
        Loading...
      </div>
    </div>
    <div
      v-if="recipient_name"
      class="info-box"
    >
      <div class="line">
        <span class="left">收件人</span>
        <span class="right">{{ recipient_name }}</span>
      </div>
      <div class="line">
        <span class="left">运单ID</span>
        <span class="right">{{ shipment_id_text }}</span>
      </div>
      <div class="line">
        <span class="left">包裹数量</span>
        <span class="right">{{ parcel_amount }}件</span>
      </div>
      <div class="line">
        <span class="left">照片</span>
        <div class="right">
          <viewer :images="packed_images">
            <img
              v-for="item in packed_images"
              :key="item"
              class="img"
              :src="item"
            >
          </viewer>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <span
        v-if="shipmentIdError"
        class="red-text"
      >请输入运单ID</span>
      <div
        v-if="recipient_name"
        class="d-flex justify-content-center mb-2"
      >
        <b-button
          variant="relief-success submit-btn"
          @click="handleSubmit"
        >
          确认取货
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BFormInput,
    BButton,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      shipment_id: '',
      shipmentIdError: false,
      packed_images: [],
      recipient_name: '',
      parcel_amount: 0,
      isLoading: false,
      shipment_id_text: '',
    }
  },
  watch: {
    shipment_id(val) {
      if (val) {
        this.shipmentIdError = false
      } else {
        this.shipmentIdError = true
      }
    },
  },
  methods: {
    async handleSeaShipment() {
      try {
        this.isLoading = true
        const data = await this.$api.seaShipment({ shipmentId: this.shipment_id })
        this.isLoading = false
        if (data.code === 200) {
          this.packed_images = data.data.packed_images
          this.parcel_amount = data.data.parcel_amount
          this.recipient_name = data.data.recipient_name
          this.shipment_id_text = data.data.shipment_id
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    handleSubmit() {
      if (!this.shipment_id) {
        this.shipmentIdError = true
      } else {
        this.shipmentIdError = false
        this.$bvModal
          .msgBoxConfirm(`我已确认${this.shipment_id}与客人提供的信息匹配`, {
            title: '请确认',
            cancelVariant: 'outline-secondary',
            centered: true,
            okTitle: '变更状态',
            cancelTitle: '返回',
          })
          .then(async value => {
            if (value) {
              const data = await this.$api.confirmPickup({ shipment_id: this.shipment_id })
              if (data.code === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notification',
                    icon: 'InfoIcon',
                    text: '变更状态成功',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                })
              }
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.input-text{
  display: block;
  min-width: 180px;
  font-size: 26px;
}
.input{
  width: 30%;
}
.btn{
  width: 100px;
}
.info-box{
  margin-top: 100px;
}
.line{
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
}
.left{
  width: 100px;
  text-align: right;
  margin-right: 50px;
}
.img{
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 6px;
  cursor: pointer;
}
.submit-btn{
  width: 180px;
}
.red-border{
  border: 2px solid red;
}
.red-text{
  color: red;
  display: block;
  text-align: center;
}
.btn-box{
  margin-top: 100px;
}
.loading-text{
  font-size: 26px;
}
.loading-box{
  margin-top:100px;
}
</style>
